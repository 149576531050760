import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import "./../styles/global.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    console.log("+-----------------------------------------------+\n" +
                "|                                               |\n" +
                "|                   Enjoy                       |\n" +
                "|                                               |\n" +
                "|  https://www.youtube.com/watch?v=1uobO9VNi14  |\n" +
                "|                                               |\n" +
                "+-----------------------------------------------+")

    return (
      <Layout location={this.props.location} title={'// ' + siteTitle}>
        <SEO title="Blog" />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>

                <h3 style={{marginTop: '.5rem'}}>
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />


              </section>
              <footer>
              <small>{node.frontmatter.date}</small> { node.frontmatter.tags.map( (tag) => {
                  return(<span className='post-tag'>#{tag}</span>)
                }) }
              </footer>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
